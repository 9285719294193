export const keywords = [
    `eliel`,
    `dabush`,
    `senior`,
    `software engineer`,
    `gatsby`,
    `tailwind`,
    `react`,
    `tailwindcss`,
    `graphql`,
    `developer`,
    `web`,
    `programming`,
    `tech blog`,
    `graphql`,
    `docker`,
    `k8s`,
    `kubernetes`,
    `aws`,
    `git`,
    `system design`,
    `refactor`,
    `unit test`,
    `e2e`
];
